<template>
  <div class="Part">
    <div class="input">
      <el-row>
        <el-button type="primary" size="mini" @click="batchModeSetting"
          >批量设置情景模式</el-button
        >
        <el-dialog
          title="批量设置情景模式"
          v-loading="batchMode.loading"
          :visible.sync="batchMode.dialogbatchMode"
          @close="dialogbatchModeClose"
          :close-on-click-modal="false"
          width="900px"
        >
          <el-steps
            :active="batchMode.active"
            finish-status="success"
            simple
            style="margin-bottom:10px"
          >
            <el-step title="1.选择房屋"></el-step>
            <el-step title="2.模式设置"></el-step>
          </el-steps>
          <!-- 步骤一 -->
          <div v-if="batchMode.active == 0">
            房屋类型：
            <el-select
              v-model="house.params.hsAddCity"
              @change="getHouse()"
              size="mini"
              style="width:150px"
              clearable
            >
              <el-option label="酒店房间" value="酒店房间"></el-option>
              <el-option label="公区" value="公区"></el-option>
              <el-option label="办公室" value="办公室"></el-option> </el-select
            >&nbsp;&nbsp; 房屋地址： 
            <el-input
              @change="getHouse"
              size="mini"
              v-model="house.params.address"
              clearable
              autocomplete="off"
              style="width:150px"
            ></el-input>
            <el-table
              :data="house.dataList"
              ref="batchModeHouse"
              :key="house.houseKey"
              @selection-change="houseTableChange"
              stripe
              style="width:100%;"
              height="40vh"
            >
              <!--数据表-->
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column
                prop="hsAddCity"
                label="房屋类型"
                min-width="100px"
              ></el-table-column>
              <el-table-column
                prop="hsAddCommunity"
                label="房屋地址"
                min-width="100px"
              ></el-table-column>
            </el-table>
          </div>
          <!-- 步骤二 -->
          <div v-if="batchMode.active == 1">
            选择情景模式：
            <el-select
              v-model="batchMode.spdId"
              clearable
              size="mini"
              style="width:110px;margin-right: 10px"
            >
              <el-option
                v-for="item in sceneTypeList"
                :key="item.spdId"
                :label="item.spdDescribe"
                :value="item.spdId"
              ></el-option>
            </el-select>
            <!-- 启用智控：
            <el-select
              v-model="batchMode.jsroIntelligentControlEnabled"
              size="mini"
              style="width:110px"
            >
              <el-option label="启用" value="1"></el-option>
              <el-option label="不启用" value="2"></el-option>
            </el-select> -->
            <el-button
              type="primary"
              size="mini"
              v-if="batchMode.spdId"
              @click="intelligentControlDialogShow('批量')"
              >智控</el-button
            >
            <el-table
              v-if="batchMode.spdId"
              :data="batchMode.deviceTypeList"
              style="width:100%;"
              height="360px"
            >
              <el-table-column width="55"></el-table-column>
              <el-table-column
                prop="secondaryTypeName"
                label="设备安装位置"
                min-width="100px"
              ></el-table-column>
              <el-table-column label="是否启用" min-width="100px">
                <template slot-scope="scope">
                  <el-switch
                    v-model="scope.row.isEnable"
                    :active-value="1"
                    :inactive-value="0"
                    active-text="启用"
                    inactive-text="不启用"
                    class="switchStyle"
                    active-color="#00A854"
                    inactive-color="#F04134"
                  ></el-switch>
                </template>
              </el-table-column>
              <el-table-column label="开关状态" min-width="100px">
                <template slot-scope="scope">
                  <el-switch
                    v-model="scope.row.jsroImsState.state"
                    active-value="开"
                    inactive-value="关"
                    :disabled="scope.row.isEnable == 0"
                    active-text="开"
                    inactive-text="关"
                    class="switchStyle"
                    active-color="#00A854"
                    inactive-color="#F04134"
                  ></el-switch>
                </template>
              </el-table-column>
              <el-table-column label="详细设置" min-width="100px">
                <template slot-scope="scope">
                  <el-button
                    @click="detailedSettings(scope.row, '批量')"
                    v-show="
                      airConditioner.secondType.indexOf(
                        scope.row.devSecondType
                      ) > -1 ||
                        coolingAndHeatingLamp.secondType.indexOf(
                          scope.row.devSecondType
                        ) > -1 ||
                        lnfraredAlarm.secondType.indexOf(
                          scope.row.devSecondType
                        ) > -1 ||
                        adjustingLamp.secondType.indexOf(
                          scope.row.devSecondType
                        ) > -1 ||
                        percentageCurtain.secondType.indexOf(
                          scope.row.devSecondType
                        ) > -1
                    "
                    :disabled="scope.row.isEnable == 0"
                    size="small"
                    type="warning"
                    >详细设置</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- 底部按钮 -->
          <div slot="footer">
            <el-button
              @click="batchModePreviousStep"
              v-if="batchMode.active == 1"
              >上一步</el-button
            >
            <el-button
              type="primary"
              @click="batchModeNextStep"
              v-if="batchMode.active == 0"
              >下一步</el-button
            >
            <el-button
              type="primary"
              @click="batchModeSubmit"
              v-if="batchMode.active == 1"
              >提交</el-button
            >
          </div>
        </el-dialog>
        <el-dialog
          title="详细设置"
          :visible.sync="batchMode.dialogDetailedSettings"
          @close="assignmentDetailedSettings"
          :close-on-click-modal="false"
          width="400px"
        >
          <el-form
            v-if="
              airConditioner.secondType.indexOf(batchMode.row.devSecondType) >
                -1
            "
          >
            <el-form-item>
              <el-col :span="3">温度：</el-col>
              <el-col :span="21">
                <el-input
                  @change="temperatureChange"
                  v-model="airConditioner.mode.temperature"
                  clearable
                  size="small"
                  placeholder="请输入16到30之间的数字"
                ></el-input>
              </el-col>
            </el-form-item>
            <el-form-item>
              <el-col :span="3">模式：</el-col>
              <el-col :span="21">
                <el-select
                  v-model="airConditioner.mode.pattern"
                  clearable
                  size="small"
                  style="width: 100%"
                >
                  <el-option label="自动" value="自动"></el-option>
                  <el-option label="制冷" value="制冷"></el-option>
                  <el-option label="制热" value="制热"></el-option>
                  <el-option label="送风" value="送风"></el-option>
                  <el-option label="除湿" value="除湿"></el-option>
                </el-select>
              </el-col>
            </el-form-item>
            <el-form-item>
              <el-col :span="3">风速：</el-col>
              <el-col :span="21">
                <el-select
                  v-model="airConditioner.mode.windSpeed"
                  clearable
                  size="small"
                  style="width: 100%"
                >
                  <el-option label="自动" value="自动"></el-option>
                  <el-option label="高" value="高"></el-option>
                  <el-option label="中" value="中"></el-option>
                  <el-option label="低" value="低"></el-option>
                </el-select>
              </el-col>
            </el-form-item>
          </el-form>
          <el-form
            v-else-if="
              coolingAndHeatingLamp.secondType.indexOf(
                batchMode.row.devSecondType
              ) > -1
            "
          >
            <el-form-item>
              <el-row>
                <el-col :span="3">亮度：</el-col>
                <el-col :span="21">
                  <el-slider
                    v-model="coolingAndHeatingLamp.mode.brightness"
                    v-if="
                      ['76'].indexOf(scenarioSetting.mode.row.devSecondType) >
                        -1
                    "
                    :max="10"
                    :min="1"
                  ></el-slider>
                  <el-slider
                    v-model="coolingAndHeatingLamp.mode.brightness"
                    :step="5"
                    :min="1"
                    v-else
                  ></el-slider>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item>
              <el-row>
                <el-col :span="3">色温：</el-col>
                <el-col :span="21">
                  <el-slider
                    v-model="coolingAndHeatingLamp.mode.colorTemperature"
                    v-if="
                      ['76'].indexOf(scenarioSetting.mode.row.devSecondType) >
                        -1
                    "
                    :min="1"
                  ></el-slider>
                  <el-slider
                    v-model="jsroImsState.colorTemperature"
                    :step="5"
                    :min="1"
                    v-else
                  ></el-slider>
                </el-col>
              </el-row>
            </el-form-item>
          </el-form>
          <el-form
            v-else-if="adjustingLamp.secondType.indexOf(batchMode.row.devSecondType) > -1"
          >
            <el-form-item>
              <el-row>
                <el-col :span="3">亮度：</el-col>
                <el-col :span="21">
                  <el-slider
                    v-model="adjustingLamp.mode.brightness"
                    :step="5"
                    :min="1"
                  ></el-slider>
                </el-col>
              </el-row>
            </el-form-item>
          </el-form>
          <el-form
            v-else-if="
              percentageCurtain.secondType.indexOf(
                scenarioSetting.mode.row.devSecondType
              ) > -1
            "
          >
            <el-form-item>
              <el-row>
                <el-col :span="24">
                  <div
                    class="block"
                    v-if="
                      percentageCurtain.secondType.indexOf(
                        scenarioSetting.mode.row.devSecondType
                      ) > -1
                    "
                  >
                    <el-slider
                      v-model="percentageCurtain.mode.percentCurtain"
                      @change="percentChange"
                      :step="10"
                      :format-tooltip="formatTooltip"
                      show-stops
                    >
                    </el-slider>
                  </div>
                </el-col>
              </el-row>
            </el-form-item>
          </el-form>
          <el-form
            v-else-if="
              lnfraredAlarm.secondType.indexOf(
                scenarioSetting.mode.row.devSecondType
              ) > -1
            "
          >
            <el-form-item>
              <el-col :span="5" style="">开关</el-col>
              <el-col :span="19">
                <el-select
                  v-model="lnfraredAlarm.mode.switchs"
                  clearable
                  size="small"
                  style="width: 100%"
                >
                  <el-option label="保持" :value="0"></el-option>
                  <el-option label="开" :value="1"></el-option>
                  <el-option label="关" :value="2"></el-option>
                </el-select>
              </el-col>
            </el-form-item>
            <el-form-item>
              <el-col :span="5">事件周期</el-col>
              <el-col :span="19">
                <el-input-number
                  size="small"
                  v-model="lnfraredAlarm.mode.spacing"
                  controls-position="right"
                  :min="3"
                  :max="10"
                ></el-input-number>
              </el-col>
            </el-form-item>
            <el-form-item>
              <el-col :span="5">报警阈值</el-col>
              <el-col :span="19">
                <el-input
                  size="small"
                  v-model.number="lnfraredAlarm.mode.threshold"
                  placeholder="请输入1-65535"
                ></el-input>
              </el-col>
            </el-form-item>
            <el-form-item>
              <el-col :span="5">灵敏度等级</el-col>
              <el-col :span="19">
                <el-input
                  size="small"
                  v-model.number="lnfraredAlarm.mode.sensitivity"
                  placeholder="请输入1-255"
                ></el-input>
              </el-col>
            </el-form-item>
          </el-form>
        </el-dialog>

        <el-button type="primary" size="mini" @click="batchPanelSetting"
          >批量设置情景联动</el-button
        >
        <el-dialog
          title="批量设置情景联动"
          v-loading="batchPanel.loading"
          :visible.sync="batchPanel.dialogbatchPanel"
          @close="dialogbatchPanelClose"
          :close-on-click-modal="false"
          width="600px"
        >
          <el-steps
            :active="batchPanel.active"
            finish-status="success"
            simple
            style="margin-bottom:10px"
          >
            <el-step title="1.选择房屋"></el-step>
            <el-step title="2.设置情景联动"></el-step>
          </el-steps>
          <div v-if="batchPanel.active == 0">
            房屋类型：
            <el-select
              v-model="house.params.hsAddCity"
              @change="getHouse()"
              size="mini"
              style="width:150px"
              clearable
            >
              <el-option label="酒店房间" value="酒店房间"></el-option>
              <el-option label="公区" value="公区"></el-option>
              <el-option label="办公室" value="办公室"></el-option> </el-select
            >房屋地址：
            <el-input
              @change="getHouse()"
              size="mini"
              clearable
              v-model="house.params.address"
              autocomplete="off"
              style="width:150px"
            ></el-input>
            <el-table
              :data="house.dataList"
              ref="batchPanelHouse"
              :key="house.houseKey"
              @selection-change="houseTableChange"
              stripe
              style="width:100%;"
              height="40vh"

            >
              <!--数据表-->
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column
                prop="hsAddCity"
                label="房屋类型"
                min-width="100px"
              ></el-table-column>
              <el-table-column
                prop="hsAddCommunity"
                label="房屋地址"
                min-width="100px"
              ></el-table-column>
            </el-table>
          </div>
          <el-form
            v-if="batchPanel.active == 1"
            label-with="80px"
            align="center"
          >
            <el-form-item>
              选择设备：
              <el-select
                @change="batchPanelChange(batchPanel.panelKeyList, 0)"
                v-model="batchPanel.devSecondType"
                size="mini"
                style="width:200px"
              >
                <el-option
                  v-for="(item, i) in batchPanel.panelTypeList"
                  :key="i"
                  :label="item.secondaryTypeName"
                  :value="item.devSecondType"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-for="(item, i) in batchPanel.panelKeyList" :key="i">
              {{ item.name }}：
              <el-select
                v-model="item.spdId"
                size="mini"
                style="width:200px"
                clearable
              >
                <el-option
                  v-for="value in sceneTypeList"
                  :key="value.spdId"
                  :label="value.spdDescribe"
                  :value="value.spdId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div slot="footer">
            <el-button
              @click="batchPanelPreviousStep"
              v-if="batchPanel.active == 1"
              >上一步</el-button
            >
            <el-button
              type="primary"
              @click="batchPanelNextStep"
              v-if="batchPanel.active == 0"
              >下一步</el-button
            >
            <el-button
              type="primary"
              @click="batchPanelSubmit"
              v-if="batchPanel.active == 1"
              >提交</el-button
            >
          </div>
        </el-dialog>

        <el-button
          type="primary"
          size="mini"
          @click="modeTypeSetting.dialogModeTypeSetting = true"
          >设置情景模式类型</el-button
        >&nbsp;
        <el-dialog
          title="设置情景模式类型"
          v-loading="modeTypeSetting.loading"
          :visible.sync="modeTypeSetting.dialogModeTypeSetting"
          @close="dialogModeTypeSettingClose"
          :close-on-click-modal="false"
          width="600px"
        >
          <el-button
            size="small"
            @click="modeTypeSetting.addModeType.dialogAddModeType = true"
            >添加情景模式类型</el-button
          >
          <el-dialog
            title="添加情景类型窗口"
            v-loading="modeTypeSetting.addModeType.loading"
            :visible.sync="modeTypeSetting.addModeType.dialogAddModeType"
            @close="dialogAddModeTypeClose"
            :close-on-click-modal="false"
            append-to-body
            width="600px"
          >
            <el-form>
              <el-form-item
                v-for="(item, i) in modeTypeSetting.addModeType.dataList"
                :key="i"
              >
                <el-col :span="11">
                  情景类型：
                  <el-input
                    size="small"
                    type="text"
                    v-model="item.spdDescribe"
                    style="width:150px"
                    required="required"
                    clearable
                  ></el-input
                  >&nbsp;&nbsp;&nbsp;
                </el-col>
                <el-col :span="9">
                  微信端首页可见性：
                  <el-switch
                    :active-value="1"
                    :inactive-value="2"
                    v-model="item.spdPatternsAreVisible"
                    active-text="可见"
                    inactive-text="不可见"
                    class="switchStyle"
                    active-color="#00A854"
                    inactive-color="#F04134"
                  ></el-switch>
                </el-col>
                <el-col :span="4">
                  <el-button
                    @click="delModeTypeLine(i)"
                    type="primary"
                    size="small"
                    icon="el-icon-close"
                    plain
                    circle
                  ></el-button>
                </el-col>
              </el-form-item>
              <el-button
                @click="addModeTypeLine"
                size="medium"
                type="primary"
                plain
                circle
                icon="el-icon-plus"
              ></el-button>
            </el-form>
            <div slot="footer">
              <el-button
                @click="modeTypeSetting.addModeType.dialogAddModeType = false"
                >取 消</el-button
              >
              <el-button type="primary" @click="doAddModeType">添加</el-button>
            </div>
          </el-dialog>

          <el-table
            :data="sceneTypeList"
            height="420px"
            style="margin-bottom:5px"
          >
            <!--数据表-->
            <el-table-column label="情景模式" min-width="50px">
              <template slot-scope="scope">
                <el-col v-if="scope.row.buttonShow == true">
                  <el-input
                    ref="spdDescribe"
                    v-model="scope.row.spdDescribe"
                    size="mini"
                    style="width:150px"
                    clearable
                  ></el-input>
                </el-col>
                <span v-else style="width:150px">{{
                  scope.row.spdDescribe
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="微信端可见性" min-width="50px">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.spdPatternsAreVisible"
                  active-text="可见"
                  inactive-text="不可见"
                  class="switchStyle"
                  active-color="#00A854"
                  inactive-color="#F04134"
                  :active-value="1"
                  :inactive-value="2"
                ></el-switch>
              </template>
            </el-table-column>
            <el-table-column label="操作" min-width="50px">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="warning"
                  @click="cancelModify(scope.row)"
                  v-if="scope.row.buttonShow == true"
                  >取消</el-button
                >
                <el-button
                  type="success"
                  size="mini"
                  @click="saveModify(scope.row)"
                  v-if="scope.row.buttonShow == true"
                  >保存</el-button
                >
                <el-button
                  type="primary"
                  size="mini"
                  @click="editSceneType(scope.row)"
                  v-else
                  >编辑</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div slot="footer">
            <el-button type="primary" @click="saveModeTypeSetting"
              >保 存</el-button
            >
            <el-button @click="modeTypeSetting.dialogModeTypeSetting = false"
              >取 消</el-button
            >
          </div>
        </el-dialog>
      </el-row>
      <el-row style="padding: 5px 5px 5px 0;">
        房屋类型：
        <el-select
          v-model="allScenario.params.hsAddCity"
          @change="getAllScenario"
          size="mini"
          style="width:150px; margin-right: 20px"
          clearable
        >
          <el-option label="酒店房间" value="酒店房间"></el-option>
          <el-option label="公区" value="公区"></el-option>
          <el-option label="办公室" value="办公室"></el-option> </el-select
        >&nbsp;&nbsp; 房屋地址：
        <el-input
          @change="getAllScenario"
          size="mini"
          v-model="allScenario.params.hsAddCommunity"
          clearable
          style="width:15%"
        ></el-input>
      </el-row>
      <el-row>
        <el-table
          @row-dblclick="tableDblclick"
          v-loading
          :data="allScenario.dataList"
          highlight-current-row
          :height="allScenario.tableHeight"
          style="width:100%;"
        >
          <el-table-column
            prop="hsAddCity"
            label="房屋类型"
            min-width="100px"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="hsAddCommunity"
            label="房屋地址"
            min-width="100px"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="allSpdDescribe"
            label="情景模式"
            min-width="100px"
            show-overflow-tooltip
          ></el-table-column>
        </el-table>
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :total="allScenario.total"
          :page-size="allScenario.params.limit"
          :page-count="allScenario.pages"
          :current-page="allScenario.params.cursor"
          @current-change="allScenarioCurrent"
          @size-change="allScenarioSize"
          :page-sizes="[5, 10, 20, 40]"
          background
          align="center"
        ></el-pagination>
        <el-dialog
          @close="dialogScenarioSettingClose"
          v-loading="scenarioSetting.loading"
          title="单个设置情景模式"
          :visible.sync="scenarioSetting.dialogScenarioSetting"
        >
          <el-tabs v-model="scenarioSetting.tabPane" @tab-click="tabClick">
            <el-tab-pane label="情景模式" name="first">
              <el-row>
                <el-col :span="12">
                  选择情景模式：
                  <el-select
                    @change="getDeviceByHsId(true, false)"
                    @clear="clearDeviceByHsId"
                    v-model="scenarioSetting.mode.spdId"
                    clearable
                    size="mini"
                    style="width:110px; margin-right: 10px"
                  >
                    <el-option
                      v-for="item in sceneTypeList"
                      :key="item.spdId"
                      :label="item.spdDescribe"
                      :value="item.spdId"
                    ></el-option>
                  </el-select>
                  <!-- 启用智控：
                                    <el-select
                                      v-model="scenarioSetting.mode.jsroIntelligentControlEnabled"
                                      size="mini"
                                      style="width:110px"
                                    >
                                      <el-option label="启用" :value="1"></el-option>
                                      <el-option label="不启用" :value="2"></el-option>
                                    </el-select> -->
                  <el-button
                    type="primary"
                    @click="intelligentControlDialogShow('单个')"
                    size="mini"
                    v-if="scenarioSetting.mode.status"
                    >智控</el-button
                  >
                </el-col>
                <el-col
                  :span="12"
                  v-if="scenarioSetting.mode.status"
                  style="height:47px;"
                >
                  网关：
                  <el-select
                    @change="getScenePairId"
                    @clear="clearScenePairId"
                    v-model="GatewayList.devAuthSecret"
                    clearable
                    size="mini"
                    style="width:110px; margin-right: 10px"
                  >
                    <el-option
                      v-for="item in GatewayList"
                      :key="item.spdId"
                      :label="item.spdDescribe"
                      :value="item.devAuthSecret"
                    ></el-option>
                  </el-select>
                  <el-button
                    v-if="scenarioSetting.mode.status1 == true"
                    type="primary"
                    @click="sceneCodePair()"
                    size="mini"
                    >匹配</el-button
                  >
                </el-col>
              </el-row>
              <el-table
                v-if="scenarioSetting.mode.spdId"
                :data="scenarioSetting.mode.deviceList"
                style="width:100%;"
                height="360px"
              >
                <el-table-column width="55"></el-table-column>
                <el-table-column
                  prop="devNickname"
                  label="设备列表"
                  min-width="100px"
                ></el-table-column>
                <el-table-column label="是否启用" min-width="100px">
                  <template slot-scope="scope">
                    <el-switch
                      v-model="scope.row.isEnable"
                      :active-value="1"
                      :inactive-value="0"
                      active-text="启用"
                      inactive-text="不启用"
                      class="switchStyle"
                      active-color="#00A854"
                      inactive-color="#F04134"
                    ></el-switch>
                  </template>
                </el-table-column>
                <el-table-column label="开关状态" min-width="100px">
                  <template slot-scope="scope">
                    <el-switch
                      v-model="scope.row.jsroImsState.state"
                      active-value="开"
                      inactive-value="关"
                      :disabled="scope.row.isEnable == 0"
                      active-text="开"
                      inactive-text="关"
                      class="switchStyle"
                      active-color="#00A854"
                      inactive-color="#F04134"
                    ></el-switch>
                  </template>
                </el-table-column>
                <el-table-column label="详细设置" min-width="100px">
                  <template slot-scope="scope">
                    <el-button
                      @click="detailedSettings(scope.row, '单个')"
                      v-show="
                        airConditioner.secondType.indexOf(
                          scope.row.devSecondType
                        ) > -1 ||
                          coolingAndHeatingLamp.secondType.indexOf(
                            scope.row.devSecondType
                          ) > -1 ||
                          lnfraredAlarm.secondType.indexOf(
                            scope.row.devSecondType
                          ) > -1 ||
                          adjustingLamp.secondType.indexOf(
                            scope.row.devSecondType
                          ) > -1 ||
                          underfloorHeatingLamp.secondType.indexOf(
                            scope.row.devSecondType
                          ) > -1 ||
                          percentageCurtain.secondType.indexOf(
                            scope.row.devSecondType
                          ) > -1
                      "
                      :disabled="scope.row.isEnable == 0"
                      size="small"
                      type="warning"
                      >详细设置</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <el-button
                type="primary"
                @click="scenarioSettingModeSubmit"
                style="margin-top:10px;float:right"
                >提交</el-button
              >
            </el-tab-pane>
            <el-tab-pane label="情景联动" name="second">
              <el-form align="center">
                <el-form-item>
                  选择设备：
                  <el-select
                    @change="
                      batchPanelChange(scenarioSetting.panel.panelKeyList, 1)
                    "
                    v-model="scenarioSetting.panel.jourDeviceId"
                    size="mini"
                    style="width:200px"
                  >
                    <el-option
                      v-for="(item, i) in scenarioSetting.panel.panelDeviceList"
                      :key="i"
                      :label="item.devNickname"
                      :value="item.jourDeviceId"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  v-for="(item, i) in scenarioSetting.panel.panelKeyList"
                  :key="i"
                >
                  {{ item.name }}：
                  <el-select
                    v-model="item.spdId"
                    size="mini"
                    style="width:200px"
                    clearable
                  >
                    <el-option
                      v-for="value in sceneTypeList"
                      :key="value.spdId"
                      :label="value.spdDescribe"
                      :value="value.spdId"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-button
                  type="primary"
                  @click="scenarioSettingPanelSubmit"
                  style="margin-top:10px;float:right"
                  >提交</el-button
                >
              </el-form>
            </el-tab-pane>
          </el-tabs>
        </el-dialog>
        <el-dialog
          title="详细设置"
          :visible.sync="scenarioSetting.mode.dialogDetailedSettings"
          @close="assignmentDetailedSettings"
          :close-on-click-modal="false"
          width="400px"
        >
          <el-form
            v-if="
              airConditioner.secondType.indexOf(
                scenarioSetting.mode.row.devSecondType
              ) > -1
            "
          >
            <el-form-item>
              <el-col :span="3">温度：</el-col>
              <el-col :span="21">
                <el-input
                  @change="temperatureChange"
                  v-model="airConditioner.mode.temperature"
                  clearable
                  size="small"
                  placeholder="请输入16到30之间的数字"
                ></el-input>
              </el-col>
            </el-form-item>
            <el-form-item>
              <el-col :span="3">模式：</el-col>
              <el-col :span="21">
                <el-select
                  v-model="airConditioner.mode.pattern"
                  clearable
                  size="small"
                  style="width: 100%"
                >
                  <el-option
                    label="自动"
                    value="自动"
                    v-if="scenarioSetting.mode.row.devSecondType != 49"
                  ></el-option>
                  <el-option label="制冷" value="制冷"></el-option>
                  <el-option label="制热" value="制热"></el-option>
                  <el-option label="送风" value="送风"></el-option>
                  <el-option
                    label="除湿"
                    value="除湿"
                    v-if="scenarioSetting.mode.row.devSecondType != 49"
                  ></el-option>
                </el-select>
              </el-col>
            </el-form-item>
            <el-form-item>
              <el-col :span="3">风速：</el-col>
              <el-col :span="21">
                <el-select
                  v-model="airConditioner.mode.windSpeed"
                  clearable
                  size="small"
                  style="width: 100%"
                >
                  <el-option label="自动" value="自动"></el-option>
                  <el-option label="高" value="高"></el-option>
                  <el-option label="中" value="中"></el-option>
                  <el-option label="低" value="低"></el-option>
                </el-select>
              </el-col>
            </el-form-item>
          </el-form>
          <el-form
            v-else-if="
              coolingAndHeatingLamp.secondType.indexOf(
                scenarioSetting.mode.row.devSecondType
              ) > -1
            "
          >
            <el-form-item>
              <el-row>
                <el-col :span="3">亮度：</el-col>
                <el-col :span="21">
                  <el-slider
                    v-model="coolingAndHeatingLamp.mode.brightness"
                    v-if="
                      ['76'].indexOf(scenarioSetting.mode.row.devSecondType) >
                        -1
                    "
                    :min="1"
                  ></el-slider>
                  <el-slider
                    v-model="coolingAndHeatingLamp.mode.brightness"
                    :step="5"
                    :min="1"
                    v-else
                  ></el-slider>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item>
              <el-row>
                <el-col :span="3">色温：</el-col>
                <el-col :span="21">
                  <el-slider
                    v-model="coolingAndHeatingLamp.mode.colorTemperature"
                    v-if="
                      ['76'].indexOf(scenarioSetting.mode.row.devSecondType) >
                        -1
                    "
                    :min="1"
                  ></el-slider>
                  <el-slider
                    v-model="coolingAndHeatingLamp.mode.colorTemperature"
                    :step="5"
                    :min="1"
                    v-else
                  ></el-slider>
                </el-col>
              </el-row>
            </el-form-item>
          </el-form>
          <el-form
            v-else-if="
              adjustingLamp.secondType.indexOf(
                scenarioSetting.mode.row.devSecondType
              ) > -1
            "
          >
            <el-form-item>
              <el-row>
                <el-col :span="3">亮度：</el-col>
                <el-col :span="21">
                  <el-slider
                    v-model="adjustingLamp.mode.brightness"
                    :step="5"
                    :min="1"
                  ></el-slider>
                </el-col>
              </el-row>
            </el-form-item>
          </el-form>
          <el-form
            v-else-if="
              percentageCurtain.secondType.indexOf(
                scenarioSetting.mode.row.devSecondType
              ) > -1
            "
          >
            <el-form-item>
              <el-row>
                <el-col :span="24">
                  <div
                    class="block"
                    v-if="
                      percentageCurtain.secondType.indexOf(
                        scenarioSetting.mode.row.devSecondType
                      ) > -1
                    "
                  >
                    <el-slider
                      v-model="percentageCurtain.mode.percentCurtain"
                      @change="percentChange"
                      :step="10"
                      :format-tooltip="formatTooltip"
                      show-stops
                    >
                    </el-slider>
                  </div>
                </el-col>
              </el-row>
            </el-form-item>
          </el-form>
          <el-form
            v-else-if="
              lnfraredAlarm.secondType.indexOf(
                scenarioSetting.mode.row.devSecondType
              ) > -1
            "
          >
            <el-form-item>
              <el-col :span="5" style="">开关</el-col>
              <el-col :span="19">
                <el-select
                  v-model="lnfraredAlarm.mode.switchs"
                  clearable
                  size="small"
                  style="width: 100%"
                >
                  <el-option label="保持" :value="0"></el-option>
                  <el-option label="开" :value="1"></el-option>
                  <el-option label="关" :value="2"></el-option>
                </el-select>
              </el-col>
            </el-form-item>
            <el-form-item>
              <el-col :span="5">事件周期</el-col>
              <el-col :span="19">
                <el-input-number
                  size="small"
                  v-model="lnfraredAlarm.mode.spacing"
                  controls-position="right"
                  :min="3"
                  :max="10"
                ></el-input-number>
              </el-col>
            </el-form-item>
            <el-form-item>
              <el-col :span="5">报警阈值</el-col>
              <el-col :span="19">
                <el-input
                  size="small"
                  v-model.number="lnfraredAlarm.mode.threshold"
                  placeholder="请输入1-65535"
                ></el-input>
              </el-col>
            </el-form-item>
            <el-form-item>
              <el-col :span="5">灵敏度等级</el-col>
              <el-col :span="19">
                <el-input
                  size="small"
                  v-model.number="lnfraredAlarm.mode.sensitivity"
                  placeholder="请输入1-255"
                ></el-input>
              </el-col>
            </el-form-item>
          </el-form>
          <el-form
            v-else-if="
              percentageCurtain.secondType.indexOf(
                scenarioSetting.mode.row.devSecondTyp) > -1 "
          >
            <el-form-item>
              <el-row>
                <el-col :span="24">
                  <div
                    class="block"
                    v-if="
                      percentageCurtain.secondType.indexOf(
                        scenarioSetting.mode.row.devSecondType
                      ) > -1
                    "
                  >
                    <el-slider
                      v-model="percentageCurtain.mode.percentCurtain"
                      @change="percentChange"
                      :step="10"
                      :format-tooltip="formatTooltip"
                      show-stops
                    >
                    </el-slider>
                  </div>
                </el-col>
              </el-row>
            </el-form-item>
          </el-form>
        </el-dialog>
      </el-row>

      <el-dialog
        @close="intelligentControlDialogClose"
        title="智控"
        :visible.sync="intelligentControlDialog"
      >
        <el-table :data="sensorTypeList">
          <!--数据表-->
          <el-table-column
            label="设备"
            min-width="50px"
            prop="name"
          ></el-table-column>
          <el-table-column label="操作" min-width="50px" prop="enabled">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.enabled"
                active-text="启用"
                inactive-text="不启用"
                class="switchStyle"
                active-color="#00A854"
                inactive-color="#F04134"
                :active-value="true"
                :inactive-value="false"
              ></el-switch>
            </template>
          </el-table-column>
        </el-table>
        <div slot="footer">
          <el-button
            type="primary"
            @click="saveIntelligentControl"
            v-if="singleOrMultiple"
            >提交</el-button
          >
          <el-button type="primary" @click="batchSaveIntelligent" v-else
            >提交</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: 'controlSetUp',
  data() {
    return {
      sceneTypeList: [],
      isSceneTypeList: false,
      timerSceneTabs: null,
      allSpdDescribeList: [],
      GatewayList: [],
      detailedSettingsType: '',
      allScenario: {
        tableHeight: window.innerHeight - 225,
        dataList: [],
        total: 0,
        pages: 0,
        params: {
          hsAddCity: '',
          houseAddress: '',
          limit: 10,
          cursor: 1,
        },
      },

      batchMode: {
        //批量设置电子情景数据
        loading: false,
        dialogbatchMode: false,
        active: 0,
        deviceTypeList: [],
        row: {},
        spdId: '',
        jsroIntelligentControlEnabled: '',
        dialogDetailedSettings: false,
      },

      batchPanel: {
        loading: false,
        dialogbatchPanel: false,
        active: 0,
        panelTypeList: [],
        idstId: '',
        devSecondType: '',
        panelKeyList: [],
      },

      modeTypeSetting: {
        loading: false,
        dialogModeTypeSetting: false,
        addModeType: {
          loading: false,
          dialogAddModeType: false,
          dataList: [],
        },
      },

      scenarioSetting: {
        loading: false,
        dialogScenarioSetting: false,
        tabPane: 'first',
        hsId: '',
        mode: {
          active: 0,
          deviceList: [],
          row: {},
          spdId: '',
          jsroIntelligentControlEnabled: '',
          dialogDetailedSettings: false,
          status: false,
          status1: false,
        },
        panel: {
          active: 0,
          panelDeviceList: [],
          jourDeviceId: '',
          panelKeyList: [],
        },
      },

      house: {
        dataList: [],
        clickRows: [],
        hsIdList: [],
        houseKey: 0,
        params: {
          hsAddCity: '',
          address: '',
        },
      },
      airConditioner: {
        //空调
        secondType: ['4', '42', '43', '49'],
        mode: {
          temperature: '',
          pattern: '',
          windSpeed: '',
        },
      },
      coolingAndHeatingLamp: {
        //冷暖灯
        secondType: ['31', '44', '56', '76', '97'],
        mode: {
          brightness: 0,
          colorTemperature: 0,
        },
      },
      adjustingLamp: {
        //调节灯
        secondType: ['36', '59'],
        mode: {
          brightness: 0,
        },
      },
      underfloorHeatingLamp: {
        //地暖
        secondType: ['57'],
        mode: {
          brightness: 0,
        },
      },

      lnfraredAlarm: {
        // 红外
        secondType: ['93', '95', '106'],
        mode: {
          devId: '',
          switchs: '',
          spacing: '',
          threshold: '',
          sensitivity: '',
          periodVaule: '',
          thresholdVaule: '',
          sensitivityVaule: '',
        },
      },
      //窗帘
      percentageCurtain: {
        secondType: ['108'],
        mode: {
          percentCurtain: 0,
        },
      },
      intelligentControlDialog: false,
      sensorTypeList: [],
      notEnabledSensorTypeList: [],
      jsroImsState: '[]',
      jsroImsStatePlace: '[]',
      singleOrMultiple: true, // true单个，false批量
    };
  },
  created() {
    this.getAllScenario();
    this.getSensorList();
    this.getAllSceneType();
    this.getAllMac();
  },
  methods: {
    
    formatTooltip(val) {
      //百分比窗帘格式
      return `${val}%`;
    },
    sceneCodePair() {
      this.$axios({
        method: 'post',
        url: '/common2/scenarioPatternTables/scenePair',
        params: {
          hsId: this.scenarioSetting.hsId,
          spdId: this.scenarioSetting.mode.spdId,
          mac: this.GatewayList.devAuthSecret,
          type: 0,
          sn: '',
          aisle: 0,
        },
      })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          this.isError(err, this);
        });
    },
    getAllScenario() {
      //首页表格
      this.$axios({
        method: 'get',
        url: '/common2/scenarioPatternTables/scenarioPatternTables',
        params: this.allScenario.params,
      })
        .then((res) => {
          console.log(res);
          this.allScenario.total = res.data.result.total;
          this.allScenario.dataList = res.data.result.records;
          for (let i of this.allScenario.dataList) {
            let a = i.hsAddCommunity ? `${i.hsAddCommunity}\u3000` : '';
            let b = i.hsRoomType ? `${i.hsRoomType}\u3000` : '';
            let c = i.hsAddFloor ? `${i.hsAddFloor}\u3000` : '';
            let d = i.hsAddDoorplateno ? `${i.hsAddDoorplateno}` : '';
            i.hsAddCommunity = a + b + c + d;
          }
        })
        .catch((err) => {
          this.isError(err, this);
        });
    },
    allScenarioSize(size) {
      this.allScenario.params.limit = size;
      this.getAllScenario();
    },
    allScenarioCurrent(cursor) {
      this.allScenario.params.cursor = cursor;
      this.getAllScenario();
    },
    getAllSceneType() {
      // 获取所有情景类型
      this.$axios({
        method: 'get',
        url: '/common2/scenarioPatternDescriptions',
      })
        .then((res) => {
          console.log(res)
          for (let i of res.data.result) {
            i.buttonShow = false;
            i.oldSpdDescribe = i.spdDescribe;
          }
          this.sceneTypeList = res.data.result;
        })
        .catch((err) => {
          this.isError(err, this);
        });
    },
    //获取所有网关类型

    getHouse() {
      //查询所有包含情景模式可用设备的房间、公区、办公室
      let scenarios =
        this.batchMode.dialogbatchMode == true
          ? true
          : this.batchPanel.dialogbatchPanel == true
          ? false
          : '';
      this.$axios({
        method: 'get',
        url: '/common2/house4stores/containDevices',
        params: {
          houseAddress: this.house.params.address,
          hsAddCity: this.house.params.hsAddCity,
          hsState: '正常',
          scenarios: scenarios,
        },
      })
        .then((res) => {
          this.house.dataList = res.data.result;
          for (let i of this.house.dataList) {
            let a = i.hsAddCommunity ? `${i.hsAddCommunity}\u3000` : '';
            let b = i.hsRoomType ? `${i.hsRoomType}\u3000` : '';
            let c = i.hsAddDoorplateno ? `${i.hsAddDoorplateno}` : '';
            i.hsAddCommunity = a + b + c;
          }
        })
        .catch((err) => {
          this.isError(err, this);
        });
    },
    houseTableChange(row) {
      this.house.clickRows = row;
      let ids = [];
      for (let i of row) {
        ids.push(i.hsId);
      }
      this.house.hsIdList = ids;
    },

    batchModeSetting() {
      //批量设置电子情景按钮
      this.batchMode.dialogbatchMode = true;
      this.getHouse();
    },
    batchModePreviousStep() {
      //批量设置情景模式上一步
      this.house.houseKey += 1;
      setTimeout(() => {
        //保持勾选状态
        this.house.clickRows.forEach((row) => {
          this.$refs.batchModeHouse.toggleRowSelection(row);
        });
      }, 1);
      this.batchMode.active = 0;
    },
    batchModeNextStep() {
      //批量设置情景模式下一步
      if (this.house.clickRows.length == 0) {
        this.$message.warning('请选择需要设置的地址');
      } else {
        this.batchMode.active = 1;
        this.getAllDevSecondTypes();
      }
    },
    getAllDevSecondTypes() {
      //根据未租id集合获取已设置安装位置的设备
      this.$axios({
        method: 'get',
        url: '/common2/devicePlace/deviceSecondTypes',
        params: {
          hsIds: this.house.hsIdList.join(','),
          scenarios: true,
        },
      })
        .then((res) => {
          if (res.data.result.records.length >= 1) {
            for (let i of res.data.result.records) {
              i.isEnable = 0;
              if (
                this.airConditioner.secondType.indexOf(i.devSecondType) > -1
              ) {
                //空调
                i.jsroImsState = {
                  temperature: '',
                  pattern: '',
                  windSpeed: '',
                };
              } else if (
                this.coolingAndHeatingLamp.secondType.indexOf(i.devSecondType) >
                -1
              ) {
                //冷暖灯
                i.jsroImsState = {
                  brightness: '',
                  colorTemperature: '',
                };
              } else if (
                this.adjustingLamp.secondType.indexOf(i.devSecondType) > -1
              ) {
                //调节灯
                i.jsroImsState = {
                  u: '',
                };
              }  else if (
                this.percentageCurtain.secondType.indexOf(i.devSecondType) > -1
              ) {
                //窗帘
                i.jsroImsState = {
                  percentCurtain: '',
                };
              } else {
                i.jsroImsState = {};
              }
              i.jsroImsState.devFirstType = i.devFirstType;
              i.jsroImsState.devSecondType = i.devSecondType;
              i.jsroImsState.state = '关';
              i.jsroImsState.idstId = i.idstId;
            }
            this.batchMode.deviceTypeList = res.data.result.records;
          }
        })
        .catch((err) => {
          this.isError(err, this);
        });
    },
    batchModeSubmit() {
      //批量设置情景模式提交
      if (this.batchMode.spdId) {
        let arr = [];
        console.log(this.batchMode.deviceTypeList);
        // this.batchMode.deviceTypeList = []
        if (this.batchMode.deviceTypeList == undefined)
          this.batchMode.deviceTypeList = [];
        for (let i of this.batchMode.deviceTypeList) {
          arr.push(i.jsroImsState);
        }
        this.batchMode.loading = true;
        this.$axios({
          method: 'post',
          url: '/common2/scenarioPatternTables',
          data: {
            hsIds: this.house.hsIdList,
            jsroImsState: JSON.stringify(arr),
            spdId: Number(this.batchMode.spdId),
            jsroIntelligentControlEnabled: Number(
              this.batchMode.jsroIntelligentControlEnabled
            ),
          },
        })
          .then((res) => {
            this.batchMode.loading = false;
            this.$message.success('添加成功');
            this.batchMode.dialogbatchMode = false;
            this.getAllScenario();
          })
          .catch((err) => {
            this.batchMode.loading = false;
            this.isError(err, this);
          });
      } else {
        this.$message.warning('请选择情景类型');
      }
    },
    temperatureChange() {
      //空调温度
      if (
        this.airConditioner.mode.temperature > 30 ||
        this.airConditioner.mode.temperature < 16
      ) {
        this.$message.warning('请输入合法值');
        this.airConditioner.mode.temperature = '';
      }
    },
    /**
     * 批量绑定情景联动
     */
    batchPanelSetting() {
      this.batchPanel.dialogbatchPanel = true;
      this.getHouse();
    },
    batchPanelPreviousStep() {
      //批量设置情景联动上一步
      this.house.houseKey += 1;
      setTimeout(() => {
        //保持勾选状态
        this.house.clickRows.forEach((row) => {
          this.$refs.batchPanelHouse.toggleRowSelection(row);
        });
      }, 1);
      this.batchPanel.active = 0;
    },
    batchPanelNextStep() {
      //批量设置情景联动下一步
      if (this.house.clickRows.length == 0) {
        this.$message.warning('请选择需要设置的地址');
      } else {
        this.batchPanel.active = 1;
        this.$axios({
          method: 'get',
          url: '/common2/devicePlace/deviceSecondTypes',
          params: {
            hsIds: this.house.hsIdList.join(','),
            scenarios: false,
          },
        })
          .then((res) => {
            this.batchPanel.panelTypeList = res.data.result.records;
          })
          .catch((err) => {
            this.isError(err, this);
          });
      }
    },
    batchPanelChange(array, type) {
      //面板选择
      let devSecondType = 0;
      if (type == 0) {
        devSecondType = this.batchPanel.devSecondType;
        for (let i of this.batchPanel.panelTypeList) {
          if (devSecondType == i.devSecondType) {
            this.batchPanel.idstId = i.idstId;
            break;
          }
        }
      } else {
        for (let i of this.scenarioSetting.panel.panelDeviceList) {
          if (this.scenarioSetting.panel.jourDeviceId == i.jourDeviceId) {
            devSecondType = i.devSecondType;
            break;
          }
        }
        this.scenarioSetting.panel.devSecondType;
      }
      switch (devSecondType) {
        case '17':
          array = [
            { name: '1号 按键', spdId: '', cpKeyValue: 0 },
            { name: '2号 按键', spdId: '', cpKeyValue: 1 },
            { name: '3号 按键', spdId: '', cpKeyValue: 2 },
            { name: '4号 按键', spdId: '', cpKeyValue: 3 },
          ];
          break;
        case '38':
        case '115':
        case '18':
          array = [
            { name: '插卡取电', spdId: '', cpKeyValue: 0 },
            { name: '拔卡断电', spdId: '', cpKeyValue: 1 },
          ];
          break;
        case '22':
          array = [
            { name: '1号 按键', spdId: '', cpKeyValue: 0 },
            { name: '2号 按键', spdId: '', cpKeyValue: 1 },
          ];
          break;
        case '28':
          array = [
            { name: '我回家了', spdId: '', cpKeyValue: 0 },
            { name: '我出门了', spdId: '', cpKeyValue: 1 },
            { name: '就餐模式', spdId: '', cpKeyValue: 2 },
            { name: '影音模式', spdId: '', cpKeyValue: 3 },
            { name: '会客模式', spdId: '', cpKeyValue: 4 },
            { name: '我要睡了', spdId: '', cpKeyValue: 5 },
            { name: '起床模式', spdId: '', cpKeyValue: 6 },
            { name: '我要看书', spdId: '', cpKeyValue: 7 },
            { name: '吃 饭 了', spdId: '', cpKeyValue: 8 },
            { name: '休闲模式', spdId: '', cpKeyValue: 9 },
            { name: ' 晚 安 ', spdId: '', cpKeyValue: 10 },
          ];
          break;
        case '58':
          array = [{ name: '开锁联动', spdId: '', cpKeyValue: 0 }];
          break;
        case '129':
          array = [
            { name: '我回家了', spdId: '', cpKeyValue: 2 - 1 },
            { name: '我出门了', spdId: '', cpKeyValue: 1 - 1 },
            { name: '睡眠模式', spdId: '', cpKeyValue: 3 - 1 },
            { name: '休闲模式', spdId: '', cpKeyValue: 4 - 1 },
            { name: '会客模式', spdId: '', cpKeyValue: 5 - 1 },
            { name: '影院模式', spdId: '', cpKeyValue: 6 - 1 },
            { name: '就餐模式', spdId: '', cpKeyValue: 7 - 1 },
          ];
        default:
          break;
      }
      if (type == 0) {
        this.batchPanel.panelKeyList = array;
      } else if (type == 1) {
        this.scenarioSetting.panel.panelKeyList = array;
        console.log(this.scenarioSetting.panel.panelKeyList);
        this.getControlPanel();
      }
    },
    selectScene() {
      let newArr = [];
      // console.log(this.allSpdDescribeList, this.sceneTypeList)
      for (let i = 0; i < this.allSpdDescribeList.length; i++) {
        for (let j = 0; j < this.sceneTypeList.length; j++) {
          if (
            this.sceneTypeList[j].spdDescribe.indexOf(
              this.allSpdDescribeList[i]
            ) > -1
          ) {
            newArr.push(this.sceneTypeList[j]);
          }
        }
      }
      this.sceneTypeList = newArr;
      // console.log(this.sceneTypeList)
      this.isSceneTypeList = true;
      // console.log(this.isSceneTypeList)
    },
    //查询情景面板
    getControlPanel() {
      this.scenarioSetting.loading = true;
      this.$axios({
        method: 'get',
        url: '/common2/controlPanels',
        params: {
          hsId: this.scenarioSetting.hsId,
          jourDeviceId: this.scenarioSetting.panel.jourDeviceId,
        },
      })
        .then((res) => {
          console.log(res);
          for (let i in res.data.result.records) {
            this.scenarioSetting.panel.panelKeyList[i].spdId = Number(
              res.data.result.records[i].spdId
            );
          }
          this.scenarioSetting.loading = false;
        })
        .catch((err) => {
          this.scenarioSetting.loading = false;
          this.isError(err, this);
        });
    },
    //设置情景模式类型
    batchPanelSubmit() {
      // 批量设置情景联动提交
      if (!this.batchPanel.devSecondType) {
        this.$message.warning('请选择面板');
        return;
      }
      let array = this.batchPanel.panelKeyList;
      for (let i in array) {
        array[i].hsIds = this.house.hsIdList;
        array[i].cpKeyValue = i;
        array[i].idstId = this.batchPanel.idstId;
      }
      this.batchPanel.loading = true;
      this.$axios({
        method: 'post',
        url: '/common2/controlPanels',
        data: array,
      })
        .then((res) => {
          this.batchPanel.loading = false;
          this.$message.success('绑定成功');
          this.batchPanel.dialogbatchPanel = false;
          this.getAllScenario();
        })
        .catch((err) => {
          this.batchPanel.loading = false;
          this.isError(err, this);
        });
    },

    addModeTypeLine() {
      //添加一行
      let obj = {
        spdDescribe: '',
        spdPatternsAreVisible: 2,
      };
      this.modeTypeSetting.addModeType.dataList.push(obj);
    },
    delModeTypeLine(i) {
      //删除一行
      this.modeTypeSetting.addModeType.dataList.splice(i, 1);
    },
    cancelModify(row) {
      row.spdDescribe = row.oldSpdDescribe;
      row.buttonShow = false;
    },
    editSceneType(row) {
      //编辑按钮
      row.buttonShow = true;
      this.$nextTick(() => {
        this.$refs.spdDescribe.focus();
      });
    },
    saveModify(row) {
      if (row.spdDescribe) {
        row.oldSpdDescribe = row.spdDescribe;
        row.buttonShow = false;
      } else {
        this.$message.warning('类型模式不能为空');
      }
    },
    saveModeTypeSetting() {
      var flag = this.sceneTypeList.some(function(item, index, array) {
        return item.buttonShow == true;
      });
      if (flag) {
        this.$message.warning('请先保存编辑项');
      } else {
        let data = [];
        for (let i of this.sceneTypeList) {
          let obj = {};
          obj.spdDescribe = i.spdDescribe;
          obj.spdId = i.spdId;
          obj.spdPatternsAreVisible = i.spdPatternsAreVisible;
          data.push(obj);
        }
        this.modeTypeSetting.loading = true;
        this.$axios({
          method: 'put',
          url: '/common2/scenarioPatternDescriptions',
          data: data,
        })
          .then((res) => {
            this.modeTypeSetting.loading = false;
            this.modeTypeSetting.dialogModeTypeSetting = false;
            this.getAllSceneType();
            this.$message.success('修改成功');
          })
          .catch((err) => {
            this.modeTypeSetting.loading = false;
            this.isError(err, this);
          });
      }
    },
    doAddModeType() {
      //执行添加情景类型
      if (this.modeTypeSetting.addModeType.dataList.length > 0) {
        var flag = this.modeTypeSetting.addModeType.dataList.some(
          (item, index, array) => {
            return item.spdDescribe == '';
          }
        );
        if (!flag) {
          this.modeTypeSetting.addModeType.loading = true;
          this.$axios({
            method: 'post',
            url: '/common2/scenarioPatternDescriptions',
            data: this.modeTypeSetting.addModeType.dataList,
          })
            .then((res) => {
              this.modeTypeSetting.addModeType.loading = false;
              this.modeTypeSetting.addModeType.dialogAddModeType = false;
              this.$message.success('添加成功');
              this.getAllSceneType();
            })
            .catch((err) => {
              this.modeTypeSetting.addModeType.loading = false;
              this.isError(err, this);
            });
        } else {
          this.$message.warning('情景类型不能为空');
        }
      } else {
        this.$message.warning('请添加情景类型');
      }
    },

    tableDblclick(row) {
      console.log(row)
      this.getAllMac();
      this.getAllSceneType();
      this.scenarioSetting.hsId = row.hsId;
      this.scenarioSetting.dialogScenarioSetting = true;
      this.allSpdDescribeList = row.allSpdDescribe.split('、');
    },

    tabClick(tab) {
      console.log(tab.name, this.isSceneTypeList);
      if (this.isSceneTypeList && tab.name == 'first') {
        let that = this;
        that.isSceneTypeList = false;
        that.getAllSceneType();
        console.log('1');
      }
      if (this.isSceneTypeList == false && tab.name == 'second') {
        let that = this;
        that.getDeviceByHsId(false, true);
        that.selectScene();
        that.isSceneTypeList = true;
        console.log('2');
      }
    },
    getDeviceByHsId(scenarios, scenariosPanel) {
      if (scenarios == true && scenariosPanel == false) {
        this.scenarioSetting.mode.status = true;
      }
      //根据未租id获取绑定的设备
      this.$axios({
        method: 'get',
        url: `/common2/house4stores/${this.scenarioSetting.hsId}/devices`,
        params: {
          scenarios: scenarios,
          scenariosPanel: scenariosPanel,
        },
      })
        .then((res) => {
          let equipment = [];
          if (scenarios) {
            equipment = res.data.result.records;
          } else if (scenariosPanel) {
            this.scenarioSetting.panel.panelDeviceList =
              res.data.result.records;
            return;
          }
          if (res.data.result.records.length >= 1) {
            //获取情景模式
            let mode = [];
            console.log(
              this.scenarioSetting.hsId,
              this.scenarioSetting.mode.spdId
            );
            this.$axios({
              method: 'get',
              url: `/common2/scenarioPatternTables/house4stores/${this.scenarioSetting.hsId}/scenarios/${this.scenarioSetting.mode.spdId}`,
            })
              .then((res) => {
                mode = JSON.parse(res.data.result.jsroImsState);
                this.notEnabledSensorTypeList = res.data.result
                  .jsroIntelligentControlList
                  ? JSON.parse(res.data.result.jsroIntelligentControlList)
                  : '';
                this.jsroImsStatePlace = JSON.parse(
                  res.data.result.jsroImsStatePlace
                );

                this.sensorTypeList.forEach((item) => {
                  if (this.notEnabledSensorTypeList.indexOf(item.id) == -1) {
                    item.enabled = false;
                  } else {
                    item.enabled = true;
                  }
                });
                this.scenarioSetting.mode.jsroIntelligentControlEnabled =
                  res.data.result.jsroIntelligentControlEnabled;
                this.equipmentObj(equipment, mode);
              })
              .catch((err) => {
                console.log(err);
                for (let item of this.sensorTypeList) {
                  item.enabled = false;
                }
                this.equipmentObj(equipment, mode);
                if (err.response.status == 500) {
                } else {
                  this.isError(err, this);
                }
              });
          }
        })
        .catch((err) => {
          this.isError(err, this);
        });
    },
    clearDeviceByHsId() {
      this.scenarioSetting.mode.status = false;
    },
    getScenePairId() {
      this.scenarioSetting.mode.status1 = true;
    },
    clearScenePairId() {
      this.scenarioSetting.mode.status1 = false;
    },
    //获取所有网关
    getAllMac() {
      this.$axios({
        method: 'get',
        url: '/common2/devices/getAllMac',
      })
        .then((res) => {
          this.GatewayList = res.data.result;
        })
        .catch((err) => {
          this.isError(err, this);
        });
    },
    equipmentObj(equipment, mode) {
      //拼接设备情景数组
      for (let i of equipment) {
        i.isEnable = 0;
        if (this.airConditioner.secondType.indexOf(i.devSecondType) > -1) {
          //空调
          i.jsroImsState = {
            temperature: '',
            pattern: '',
            windSpeed: '',
          };
        } else if (
          this.coolingAndHeatingLamp.secondType.indexOf(i.devSecondType) > -1
        ) {
          //冷暖灯
          i.jsroImsState = {
            brightness: '',
            colorTemperature: '',
          };
        } else if (
          this.adjustingLamp.secondType.indexOf(i.devSecondType) > -1
        ) {
          //调节灯
          i.jsroImsState = {
            brightness: '',
          };
        } else if (
          this.lnfraredAlarm.secondType.indexOf(i.devSecondType) > -1
        ) {
          //红外
          i.jsroImsState = {
            switchs: '',
            spacing: '',
            threshold: '',
            sensitivity: '',
          };
        } else {
          i.jsroImsState = {};
        }
        i.jsroImsState.state = '关';

        for (let j of mode) {
          if (i.devSn == j.sn) {
            i.isEnable = 1;
            for (let k in i.jsroImsState) {
              for (let l in j) {
                if (k == l) {
                  i.jsroImsState[k] = j[l];
                }
              }
            }
          }
        }

        i.jsroImsState.devFirstType = i.devFirstType;
        i.jsroImsState.devSecondType = i.devSecondType;
        i.jsroImsState.devRoad = i.devRoad;
        i.jsroImsState.sn = i.devSn;
      }
      this.scenarioSetting.mode.deviceList = equipment;
      console.log(this.scenarioSetting.mode.deviceList);
    },
    scenarioSettingModeSubmit() {
      //提交
      this.scenarioSetting.loading = true;
      let arr = [];
      for (let i of this.scenarioSetting.mode.deviceList) {
        if (i.isEnable == 1) {
          arr.push(i.jsroImsState);
        }
      }
      let scenarioPatternTablePARM = {
        hsIds: [this.scenarioSetting.hsId],
        jsroIntelligentControlEnabled: Number(
          this.scenarioSetting.mode.jsroIntelligentControlEnabled
        ),
        jsroImsState: JSON.stringify(arr),
        spdId: this.scenarioSetting.mode.spdId,
      };
      this.$axios({
        method: 'post',
        url: '/common2/scenarioPatternTables/alone',
        data: scenarioPatternTablePARM,
      })
        .then((res) => {
          this.scenarioSetting.loading = false;
          this.scenarioSetting.dialogScenarioSetting = false;
          this.$message.success('添加成功');
          this.getAllScenario();
        })
        .catch((err) => {
          this.scenarioSetting.loading = false;
          this.isError(err, this);
        });
    },

    scenarioSettingPanelSubmit() {
      //提交
      if (!this.scenarioSetting.panel.jourDeviceId) {
        this.$message.warning('请选择面板');
        return;
      }
      let array = this.scenarioSetting.panel.panelKeyList;
      let hsIds = [this.scenarioSetting.hsId];
      let arr = [];
      for (let i in array) {
        array[i].hsIds = hsIds;
        array[i].cpKeyValue = Number(
          this.scenarioSetting.panel.panelKeyList[i].cpKeyValue
        );
        array[i].jourDeviceId = this.scenarioSetting.panel.jourDeviceId;
        if (array[i].spdId != '') {
          arr.push(array[i]);
        }
      }

      console.log(arr);
      this.scenarioSetting.loading = true;
      this.$axios({
        method: 'post',
        url: '/common2/controlPanels/alone',
        data: arr,
      })
        .then((res) => {
          this.scenarioSetting.loading = false;
          this.$message.success('绑定成功');
          this.scenarioSetting.dialogScenarioSetting = false;
          this.getAllScenario();
        })
        .catch((err) => {
          this.scenarioSetting.loading = false;
          this.isError(err, this);
        });
    },

    detailedSettings(row, type) {
      console.log(row);
      //详细设置按钮
      this.detailedSettingsType = type; //详细设置类型：批量/单个
      if (this.airConditioner.secondType.indexOf(row.devSecondType) > -1) {
        //空调详细参数
        for (let i in row.jsroImsState) {
          for (let j in this.airConditioner.mode) {
            if (i == j) {
              this.airConditioner.mode[i] = row.jsroImsState[j];
            }
          }
        }
      } else if (
        this.coolingAndHeatingLamp.secondType.indexOf(row.devSecondType) > -1
      ) {
        //冷暖灯详细参数
        for (let i in row.jsroImsState) {
          for (let j in this.coolingAndHeatingLamp.mode) {
            if (i == j) {
              this.coolingAndHeatingLamp.mode[i] = Number(row.jsroImsState[j]);
            }
          }
        }
      } else if (
        this.adjustingLamp.secondType.indexOf(row.devSecondType) > -1
      ) {
        //调节灯详细参数
        for (let i in row.jsroImsState) {
          for (let j in this.adjustingLamp.mode) {
            if (i == j) {
              this.adjustingLamp.mode[i] = Number(row.jsroImsState[j]);
            }
          }
        }
      } else if (
        this.percentageCurtain.secondType.indexOf(row.devSecondType) > -1
      ) {
        //窗帘详细参数
        for (let i in row.jsroImsState) {
          for (let j in this.percentageCurtain.mode) {
            if (i == j) {
              this.percentageCurtain.mode[i] = Number(row.jsroImsState[j]);
            }
          }
        }
      } else if (
        this.lnfraredAlarm.secondType.indexOf(row.devSecondType) > -1
      ) {
        //红外
        for (let i in row.jsroImsState) {
          for (let j in this.lnfraredAlarm.mode) {
            if (i == j) {
              this.lnfraredAlarm.mode[i] = Number(row.jsroImsState[j]);
            }
          }
        }
      }
      if (type == '批量') {
        this.batchMode.row = row;
        this.batchMode.dialogDetailedSettings = true;
      } else if (type == '单个') {
        this.scenarioSetting.mode.row = row;
        this.scenarioSetting.mode.dialogDetailedSettings = true;
      }
      console.log(row);
    },
    assignmentDetailedSettings() {
      //详细设置关闭赋值
      let devSecondType =
        this.detailedSettingsType == '批量'
          ? this.batchMode.row.devSecondType
          : this.scenarioSetting.mode.row.devSecondType;
      let arr = {};
      if (this.airConditioner.secondType.indexOf(devSecondType) > -1) {
        arr = this.airConditioner.mode;
      } else if (
        this.coolingAndHeatingLamp.secondType.indexOf(devSecondType) > -1
      ) {
        arr = this.coolingAndHeatingLamp.mode;
      } else if (this.adjustingLamp.secondType.indexOf(devSecondType) > -1) {
        arr = this.adjustingLamp.mode;
      } else if (this.lnfraredAlarm.secondType.indexOf(devSecondType) > -1) {
        arr = this.lnfraredAlarm.mode;
        for (let i of this.scenarioSetting.mode.deviceList) {
          if (i.devSn == this.scenarioSetting.mode.row.devSn) {
            console.log(i.jsroImsState);
            console.log(arr);
            i.jsroImsState.periodVaule = arr.periodVaule;
            i.jsroImsState.sensitivity = arr.sensitivity;
            i.jsroImsState.spacing = arr.spacing;
            i.jsroImsState.threshold = arr.threshold;
            i.jsroImsState.thresholdVaule = arr.thresholdVaule;
            i.jsroImsState.switchs = arr.switchs;
            if (arr.switchs == 1) {
              i.jsroImsState.state = '开';
            } else if (arr.switchs == 2) {
              i.jsroImsState.state = '关';
            }
          }
        }
        return;
      }
      if (this.detailedSettingsType == '批量') {
        // 批量设置情景模式
        for (let i of this.batchMode.deviceTypeList) {
          if (i.idstId == this.batchMode.row.idstId) {
            for (let j in i.jsroImsState) {
              for (let k in arr) {
                if (j == k) {
                  i.jsroImsState[j] = arr[k];
                }
              }
            }
          }
        }
      } else if (this.detailedSettingsType == '单个') {
        // 单个设置情景模式
        console.log(this.scenarioSetting.mode.deviceList)
        for (let i of this.scenarioSetting.mode.deviceList) {
          if (i.devSn == this.scenarioSetting.mode.row.devSn) {
            console.log(i.jsroImsState);
            console.log(arr);
            for (let j in i.jsroImsState) {
              for (let k in arr) {
                if (j == k) {
                  i.jsroImsState[j] = arr[k];
                }
              }
            }
          }
        }
      }
    },

    clearHouse() {
      this.house = {
        dataList: [],
        clickRows: [],
        hsIdList: [],
        houseKey: 0,
        params: {
          hsAddCity: '',
          address: '',
        },
      };
    },
    dialogbatchModeClose() {
      //弹窗关闭清空所有状态
      this.batchMode = {
        loading: false,
        dialogbatchMode: false,
        active: 0,
        deviceList: [],
        row: {},
        spdId: '',
        jsroIntelligentControlEnabled: '',
        dialogDetailedSettings: false,
      };
      this.clearHouse();
      this.clearDetailedSettings();
    },
    dialogbatchPanelClose() {
      this.batchPanel = {
        loading: false,
        dialogbatchPanel: false,
        active: 0,
        panelTypeList: [],
        idstId: '',
        devSecondType: '',
        panelKeyList: [],
      };
      this.clearHouse();
      this.clearDetailedSettings();
    },
    dialogModeTypeSettingClose() {
      this.modeTypeSetting = {
        loading: false,
        dialogModeTypeSetting: false,
        addModeType: {
          loading: false,
          dialogAddModeType: false,
          dataList: [],
        },
      };
    },
    dialogAddModeTypeClose() {
      //设置情景类型窗口关闭
      this.modeTypeSetting.addModeType = {
        loading: false,
        dialogAddModeType: false,
        dataList: [],
      };
    },
    dialogScenarioSettingClose() {
      if (this.isSceneTypeList) {
        this.getAllSceneType();
        this.isSceneTypeList = false;
        console.log(this.isSceneTypeList);
      }
      this.GatewayList = [];
      this.scenarioSetting = {
        loading: false,
        dialogScenarioSetting: false,
        tabPane: 'first',
        hsId: '',
        mode: {
          active: 0,
          deviceList: [],
          row: {},
          spdId: '',
          jsroIntelligentControlEnabled: '',
          dialogDetailedSettings: false,
        },
        panel: {
          active: 0,
          panelDeviceList: [],
          jourDeviceId: '',
          panelKeyList: [],
        },
      };
      this.clearDetailedSettings();
    },
    clearDetailedSettings() {
      (this.airConditioner = {
        //空调
        secondType: ['4', '42', '43', '49'],
        mode: {
          temperature: '',
          pattern: '',
          windSpeed: '',
        },
      }),
        (this.coolingAndHeatingLamp = {
          //冷暖灯
          secondType: ['31', '44', '56', '76', '97'],
          mode: {
            brightness: 0,
            colorTemperature: 0,
          },
        }),
        (this.adjustingLamp = {
          //调节灯
          secondType: ['36', '59'],
          mode: {
            brightness: 0,
          },
        });
      this.percentageCurtain = {
        //窗帘
        secondType: ['108'],
        mode: {
          percentCurtain: '',
        },
      };
    },
    getSensorList() {
      // 查询传感器类型
      this.$axios({
        method: 'get',
        url: '/common2/sensorType/list',
      })
        .then((res) => {
          for (let item of res.data.result) {
            item.enabled = false;
          }
          this.sensorTypeList = res.data.result;
        })
        .catch((err) => {
          this.isError(err, this);
        });
    },
    intelligentControlDialogShow(val) {
      if (val === '批量') {
        this.singleOrMultiple = false;
        for (let item of this.sensorTypeList) {
          item.enabled = false;
        }
      }
      if (val === '单个') {
        this.singleOrMultiple = true;
      }
      this.intelligentControlDialog = true;
    },
    intelligentControlDialogClose() {},
    saveIntelligentControl() {
      let arr = [];
      this.sensorTypeList.forEach((item) => {
        if (item.enabled) {
          arr.push(item.id);
        }
      });
      console.log(this.scenarioSetting);
      this.$axios({
        method: 'post',
        url: '/common2/scenarioPatternTables/alone',
        data: {
          spdId: this.scenarioSetting.mode.spdId,
          jsroIntelligentControlList: JSON.stringify(arr),
          hsId: this.scenarioSetting.hsId,
          jsroImsState: this.jsroImsState,
        },
      })
        .then((res) => {
          this.$message.success('控制成功');
        })
        .catch((err) => {
          this.isError(err, this);
        })
        .finally(() => {
          this.intelligentControlDialog = false;
        });
    },
    batchSaveIntelligent() {
      let arr = [];
      this.sensorTypeList.forEach((item) => {
        if (item.enabled) {
          arr.push(item.id);
        }
      });
      console.log(this.scenarioSetting);
      let arr2 = [];
      for (let i of this.batchMode.deviceTypeList) {
        arr2.push(i.jsroImsState);
      }
      console.log(arr2);
      this.$axios({
        method: 'post',
        url: '/common2/scenarioPatternTables',
        data: {
          jsroIntelligentControlList: JSON.stringify(arr),
          hsIds: this.house.hsIdList,
          jsroImsState: JSON.stringify(arr2),
          spdId: Number(this.batchMode.spdId),
        },
      })
        .then((res) => {
          this.$message.success('控制成功');
        })
        .catch((err) => {
          this.isError(err, this);
        })
        .finally(() => {
          this.intelligentControlDialog = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
